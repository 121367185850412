import countries from '../assets/wrapper-web-i18n/it.json';
import countries_EN from '../assets/wrapper-web-i18n/en.json';
import countries_SP from '../assets/wrapper-web-i18n/en.json';

const engCountries = countries_EN.countries;
const espCountries = countries_SP.countries;

export const environment = {
  production: true,
  domain: ".vericode.kingsrl.com",
  baseUrl:"https://api.vericode.kingsrl.com",
  authPath:"/auth/user/login",
  logisticPath: "/logistic/locations",
  destinationPath: "/logistic/destinations",
  eventPath: "/logistic/event",
  productPath: "/core/products",
  itemPath: "/logistic/item",
  coreCompanyPath: "/core/company",
  validateCodePath: "/core/codes/validatecode/",
  validateCodesPath: "/core/codes/validatecodes/",
  volumesPath: "/logistic/report",
  countries_list: Object.entries(countries.countries).map(newC => ({code: newC[0], name: newC[1] + " " + engCountries[newC[0]] + " " + espCountries[newC[0]], id: newC[0]})).filter(f => f.id !== "null")
};

